import React from 'react'
import './Home.css'
import { Link } from 'react-router-dom';
import f from './pulkerrimaImage.webp';
import Button from 'react-bootstrap/Button';

function Home(){
    return(
        <header className="App-header">
        <img src={f} className="App-logo" alt="logo" />
        <div className = "buttonsContain">
        <Link to="/Contact">
          <Button 
            variant="ios-style" 
            style={{
              backgroundColor: '#8bbdd9d4',
              borderRadius: '20px',
              padding: '10px 20px',
              fontSize: '24px',
              fontWeight: 'bold',
              color:'rgb(150, 73, 0)',
              marginRight:'10px'
              
              
            }}
          >
            Contact Us
          </Button>
           </Link>
           <a href="Pulkerrima Menu 2023.pdf" >
  <button variant="ios-style" style={{
    backgroundColor: '#8bbdd9d4',
    borderRadius: '20px',
    padding: '10px 20px',
    fontSize: '24px',
    fontWeight: 'bold',
    color: 'rgb(150, 73, 0)'
  }}>
    Menu PDF 
  </button>
</a>
</div>
        
      </header>
    );
}

export default Home;