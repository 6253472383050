import React from 'react';
import './Contact.css';
import { FaMapMarkerAlt, FaPhone } from 'react-icons/fa';

function Contact() {
  return (
    <div className="contact-us-container">
      {outlets.map((outlet, index) => (
        <div>
            {outlet.heading === 'Thiruvananthapuram - City'?(
          <div key={index} className="outletShift">
        

          
          
          <h2>{`⚠️ We are shifting from here.\n
          Stay tuned for the new location!`}</h2> 
          <p>
             {outlet.address}
          </p>
          <p>
             {outlet.phone}
          </p>

        </div>
            )
: (
  <div key={index} className="outlet">
        

          
          
  <h2>{outlet.heading}</h2>
  <p>
    <FaMapMarkerAlt /> {outlet.address}
  </p>
  <p>
    <FaPhone /> {outlet.phone}
  </p>

</div>
)}
        </div>
        
      ))}
    </div>
  );
}

const outlets = [
  {
    heading: 'Varkala',
    address: 'Temple Road,       Varkala,695141',
    phone: '+91 9567907666',
  },
  {
    heading: 'Thiruvananthapuram - City',
    address: '404 Kalakaumudhi Lane, Kumarapuram near Medical College,695011 ',
    phone: '+91 9539600353,+91 9567907666',
  },
  {
    heading: 'Thiruvananthapuram - Kovalam ',
    address: 'First floor : The German Bakery, Light House Beach Road,695527',
    phone: '+91 9846410170,+91 9567907666',
  },
  {
    heading: 'Kochi',
    address: '1/979-b KB Jacob Road Fort Kochi,Opposite Santa Cruz Basilica,682001',
    phone: '+91 9567907666,+91 6238746233',

  },
  {
    heading: 'Kozhikode',
    address: 'Azhak Makeover studio,Ram Leela Building. Opp Azhakodi Devi temple Near Baby Memorial hospital ',
    phone: '+91 9567907666,+91 8848483351',
    
  },
  
];

export default Contact;
